import * as React from 'react'
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form'
import { CheckboxElement, CheckboxElementProps } from 'react-hook-form-mui'
import { useRevalidateFieldOnLanguageChange } from './hooks'

export type FormCheckboxProps1<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<CheckboxElementProps<TFieldValues, TName>, 'form' | 'icon' | 'checkedIcon' | 'validation' | 'ref'> & {
  form: UseFormReturn<TFieldValues>
}

export const FormCheckbox1 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  form,
  ...props
}: FormCheckboxProps1<TFieldValues, TName>): React.ReactElement => {
  useRevalidateFieldOnLanguageChange(props.name, form)
  return <CheckboxElement control={form.control} {...props} />
}
